@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */

html {
  height: 100%;
  width: 100%;
}
body {
  /* color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)); */
  height: 100%;
  width: 100%;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

/* Change scrollbar width and style */
/* Works in Firefox, Edge, and some versions of IE */

/* Set the width of the scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

/* Track (background) of the scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle (thumb) of the scrollbar */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* On hover, style the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
